var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tenant-tracker",on:{"!click":function($event){return _vm.handleToggleAction(false)}}},[_c('bg-text',{attrs:{"size":"heading-3"}},[_vm._v("Tenant Tracker")]),_c('top-action-bar',{on:{"action-search":_vm.handleGetData}}),_c('div',{staticClass:"tenant-tracker__wrapper"},[_c('div',{staticClass:"tenant-tracker__result"},[_c('bg-text',{staticClass:"tenant-tracker__result-text",attrs:{"size":"input-lg"}},[_vm._v("Menampilkan "+_vm._s(_vm.totalResultToDisplay)+" dari "+_vm._s(_vm.totalItem)+" riwayat")]),_c('div',{staticClass:"tenant-tracker__chat-loader"},[(_vm.isLoadUpdate)?_c('bg-text',{staticClass:"tenant-tracker__loader",attrs:{"size":"title-3"}},[_c('bg-icon',{attrs:{"name":"activity","size":"sm"}}),_vm._v(" Loading... ")],1):_vm._e(),_c('bg-button-icon',{attrs:{"icon":"add-plus"},on:{"click":function($event){return _vm.handleWhatsappTrackModal(true)}}},[_vm._v("Track Status Chat WA")])],1)],1)]),_c('table',{staticClass:"ss-table tenant-tracker__table",attrs:{"data-testId":"tenant-table-list"}},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(header,i){return _c('th',{key:i,staticClass:"tenant-communication__th",attrs:{"aria-label":header.text}},[_c('div',{staticClass:"table-header",class:{
              'table-header__align-left': _vm.isHeaderForDate(header.text),
            },on:{"click":function($event){_vm.updateSortingList(_vm.isHeaderForDate(header.text))}}},[_c('bg-text',{staticClass:"table-header__text",class:{ 'table-header__date': _vm.isHeaderForDate(header.text) },attrs:{"size":"title-5"}},[_vm._v(_vm._s(header.text))]),(_vm.isHeaderForDate(header.text))?_c('span',{staticClass:"table-header__icon"},[_c('bg-icon',{attrs:{"name":_vm.iconSortType,"size":"sm"}})],1):_vm._e()],1)])}),0)]),_c('tbody',[(_vm.isLoadContent)?_vm._l((5),function(i){return _c('tr',{key:i},_vm._l((8),function(j){return _c('td',{key:j},[_c('bg-skeleton',{attrs:{"width":"100%","height":24}})],1)}),0)}):(_vm.isPageReadyToDisplay)?_vm._l((_vm.communicationList),function(list,i){return _c('tr',{key:i,class:{ 'table-body__row-purple': !list.handled }},[_c('td',[_c('bg-link',_vm._b({on:{"click":function($event){return _vm.showInvalidIdAlert(
                  list.user_id,
                  'Terjadi Galat, User tidak valid!'
                )}}},'bg-link',_vm.getProfilePageLinkAttribute(list.user_id, list.id),false),[_vm._v(" "+_vm._s(list.user_name)+" ")]),_c('br'),_vm._v(" "+_vm._s(list.user_phone_number)+" ")],1),_c('td',[_c('bg-link',{attrs:{"href":_vm.getPropertyDetailOverviewPath(list.property_id),"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.showInvalidIdAlert(
                  list.property_id,
                  'Data property tidak valid'
                )}}},[_vm._v(" "+_vm._s(list.room_type_name)+" ")])],1),_c('td',[_c('bg-label-rainbow',{staticClass:"table-body__capitalize",attrs:{"color":_vm.generateLabelType(list.phase)}},[_vm._v(" "+_vm._s(list.phase)+" ")])],1),_c('td',[_c('bg-label-rainbow',{staticClass:"table-body__label",attrs:{"color":_vm.generateLabelType(list.status)}},[_vm._v(" "+_vm._s(list.status)+" ")])],1),_c('td',[_c('div',{staticClass:"table-body__date"},[_vm._v(" "+_vm._s(_vm.mixDateFormatterToDisplay(list.status_date, 'DD/MM/YYYY'))+" "),_c('br'),_c('div',{staticClass:"table-body__date--float-right"},[_vm._v(" "+_vm._s(_vm.mixDateFormatterToDisplay(list.status_date, 'HH:mm'))+" ")])])]),_c('td',[_c('bg-link',{staticClass:"table-body__ellipsis",on:{"click":function($event){return _vm.handleShowNoteModal(list)}}},[_vm._v(" "+_vm._s(_vm.handleNoteValue(list))+" ")])],1),_c('td',[_vm._v(_vm._s(list.owner_name))]),_c('td',[_c('bg-icon',{staticClass:"table-body__action-button",attrs:{"name":"more-vertical","title":"open options for this item","data-testid":"row-action-icon"},nativeOn:{"click":function($event){return _vm.handleToggleAction(i, list, list.status)}}}),(
                _vm.isShowCardAction.active && i === _vm.isShowCardAction.actionId
              )?_c('bg-card',{staticClass:"table-body__action"},_vm._l((_vm.actions),function(action,i){return _c('bg-list-item',{key:i,attrs:{"data-testid":"row-action-button"}},[(_vm.isEnableCheckInCheckout(list.status, action.icon))?_c('div',{staticClass:"table-body__action-row",on:{"click":function($event){return action.actionClick({
                      handleStatus: list.handled,
                      listId: list.id,
                      funnelId: list.funnel_id,
                      status: list.status,
                    })}}},[_c('bg-icon',{staticClass:"table-body__action-icon",attrs:{"name":action.icon,"size":"md"}}),(action.title === 'follow-up')?_c('bg-text',{attrs:{"size":"input-lg"}},[_vm._v(_vm._s(_vm.generateFollowUpStatus(list.handled))+" ")]):_c('bg-text',{attrs:{"size":"input-lg"}},[_vm._v(_vm._s(action.title))])],1):_vm._e()])}),1):_vm._e()],1)])}):_vm._e()],2)]),(!_vm.isLoadContent && _vm.isEmptyResult)?_c('bg-empty-state',{attrs:{"title":"Data Tidak Ditemukan","illustration-name":"login-failed","illustration-alt":"Data tidak ditemukan","description":"Mohon periksa kembali kata kunci yang Anda masukkan"}}):_vm._e(),(_vm.isPageReadyToDisplay)?_c('bg-pagination',{attrs:{"page-total":_vm.totalPage},on:{"click":function($event){return _vm.getTrackerList(Object.assign({}, _vm.filterParams,
        {page: _vm.currentPage}))}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_c('create-note-modal',{attrs:{"data-testid":"note-action-modal","tracker-id":_vm.trackerId,"note-value":_vm.noteValue,"tracker-status":_vm.trackerStatus,"note-id":_vm.noteId,"is-edit":!!_vm.noteId,"show-modal":_vm.isShowModalNote},on:{"update-success":_vm.refreshTrackerList,"close-note-modal":_vm.handleShowNoteModal}}),_c('whatsapp-track-modal',{attrs:{"show-modal":_vm.isShowWhatsappTrackModal,"is-new-data":_vm.isNewWhatsappTrackData,"track-data":_vm.trackerData},on:{"close-modal":_vm.handleWhatsappTrackModal}}),_c('check-in-check-out-modal',{attrs:{"tracker-id":_vm.trackerId,"booking-id":_vm.bookingId,"show-modal":_vm.isShowCheckInCheckOutModal,"confirmation-type":_vm.confirmationType},on:{"close-modal":_vm.handleShowCheckInCheckOutModal,"confirmation-success":function($event){return _vm.handleGetData(_vm.filterParams)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }