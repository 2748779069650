<template>
  <div class="tenant-tracker" @click.capture="handleToggleAction(false)">
    <bg-text size="heading-3">Tenant Tracker</bg-text>
    <top-action-bar @action-search="handleGetData" />
    <div class="tenant-tracker__wrapper">
      <div class="tenant-tracker__result">
        <bg-text size="input-lg" class="tenant-tracker__result-text"
          >Menampilkan {{ totalResultToDisplay }} dari
          {{ totalItem }} riwayat</bg-text
        >
        <div class="tenant-tracker__chat-loader">
          <bg-text
            v-if="isLoadUpdate"
            size="title-3"
            class="tenant-tracker__loader"
          >
            <bg-icon name="activity" size="sm" />
            Loading...
          </bg-text>
          <bg-button-icon
            icon="add-plus"
            @click="handleWhatsappTrackModal(true)"
            >Track Status Chat WA</bg-button-icon
          >
        </div>
      </div>
    </div>
    <table
      class="ss-table tenant-tracker__table"
      data-testId="tenant-table-list"
    >
      <thead>
        <tr>
          <th
            class="tenant-communication__th"
            v-for="(header, i) in headers"
            :aria-label="header.text"
            :key="i"
          >
            <div
              class="table-header"
              :class="{
                'table-header__align-left': isHeaderForDate(header.text),
              }"
              @click="updateSortingList(isHeaderForDate(header.text))"
            >
              <bg-text
                class="table-header__text"
                :class="{ 'table-header__date': isHeaderForDate(header.text) }"
                size="title-5"
                >{{ header.text }}</bg-text
              >
              <span
                v-if="isHeaderForDate(header.text)"
                class="table-header__icon"
              >
                <bg-icon :name="iconSortType" size="sm" />
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoadContent">
          <tr v-for="i in 5" :key="i">
            <td v-for="j in 8" :key="j">
              <bg-skeleton width="100%" :height="24" />
            </td>
          </tr>
        </template>

        <template v-else-if="isPageReadyToDisplay">
          <tr
            v-for="(list, i) in communicationList"
            :key="i"
            :class="{ 'table-body__row-purple': !list.handled }"
          >
            <td>
              <bg-link
                v-bind="getProfilePageLinkAttribute(list.user_id, list.id)"
                @click="
                  showInvalidIdAlert(
                    list.user_id,
                    'Terjadi Galat, User tidak valid!'
                  )
                "
              >
                {{ list.user_name }}
              </bg-link>
              <br />
              {{ list.user_phone_number }}
            </td>
            <td>
              <bg-link
                :href="getPropertyDetailOverviewPath(list.property_id)"
                target="_blank"
                rel="noopener noreferrer"
                @click="
                  showInvalidIdAlert(
                    list.property_id,
                    'Data property tidak valid'
                  )
                "
              >
                {{ list.room_type_name }}
              </bg-link>
            </td>
            <td>
              <bg-label-rainbow
                :color="generateLabelType(list.phase)"
                class="table-body__capitalize"
              >
                {{ list.phase }}
              </bg-label-rainbow>
            </td>
            <td>
              <bg-label-rainbow
                :color="generateLabelType(list.status)"
                class="table-body__label"
              >
                {{ list.status }}
              </bg-label-rainbow>
            </td>
            <td>
              <div class="table-body__date">
                {{ mixDateFormatterToDisplay(list.status_date, 'DD/MM/YYYY') }}
                <br />
                <div class="table-body__date--float-right">
                  {{ mixDateFormatterToDisplay(list.status_date, 'HH:mm') }}
                </div>
              </div>
            </td>
            <td>
              <bg-link
                @click="handleShowNoteModal(list)"
                class="table-body__ellipsis"
              >
                {{ handleNoteValue(list) }}
              </bg-link>
            </td>
            <td>{{ list.owner_name }}</td>
            <td>
              <bg-icon
                name="more-vertical"
                class="table-body__action-button"
                title="open options for this item"
                data-testid="row-action-icon"
                @click.native="handleToggleAction(i, list, list.status)"
              />
              <bg-card
                v-if="
                  isShowCardAction.active && i === isShowCardAction.actionId
                "
                class="table-body__action"
              >
                <bg-list-item
                  v-for="(action, i) in actions"
                  :key="i"
                  data-testid="row-action-button"
                >
                  <div
                    v-if="isEnableCheckInCheckout(list.status, action.icon)"
                    class="table-body__action-row"
                    @click="
                      action.actionClick({
                        handleStatus: list.handled,
                        listId: list.id,
                        funnelId: list.funnel_id,
                        status: list.status,
                      })
                    "
                  >
                    <bg-icon
                      :name="action.icon"
                      size="md"
                      class="table-body__action-icon"
                    />
                    <bg-text v-if="action.title === 'follow-up'" size="input-lg"
                      >{{ generateFollowUpStatus(list.handled) }}
                    </bg-text>
                    <bg-text v-else size="input-lg">{{ action.title }}</bg-text>
                  </div>
                </bg-list-item>
              </bg-card>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <bg-empty-state
      v-if="!isLoadContent && isEmptyResult"
      title="Data Tidak Ditemukan"
      illustration-name="login-failed"
      illustration-alt="Data tidak ditemukan"
      description="Mohon periksa kembali kata kunci yang Anda masukkan"
    />
    <bg-pagination
      v-if="isPageReadyToDisplay"
      v-model="currentPage"
      :page-total="totalPage"
      @click="
        getTrackerList({
          ...filterParams,
          page: currentPage,
        })
      "
    />
    <create-note-modal
      data-testid="note-action-modal"
      :tracker-id="trackerId"
      :note-value="noteValue"
      :tracker-status="trackerStatus"
      :note-id="noteId"
      :is-edit="!!noteId"
      :show-modal="isShowModalNote"
      @update-success="refreshTrackerList"
      @close-note-modal="handleShowNoteModal"
    />
    <whatsapp-track-modal
      :show-modal="isShowWhatsappTrackModal"
      :is-new-data="isNewWhatsappTrackData"
      :track-data="trackerData"
      @close-modal="handleWhatsappTrackModal"
    />
    <check-in-check-out-modal
      :tracker-id="trackerId"
      :booking-id="bookingId"
      :show-modal="isShowCheckInCheckOutModal"
      :confirmation-type="confirmationType"
      @close-modal="handleShowCheckInCheckOutModal"
      @confirmation-success="handleGetData(filterParams)"
    />
  </div>
</template>

<script>
import {
  BgButtonIcon,
  BgText,
  BgIcon,
  BgCard,
  BgLink,
  BgListItem,
  BgSkeleton,
  BgEmptyState,
  BgPagination,
  BgLabelRainbow,
} from 'bangul-vue';
import { mapGetters } from 'vuex';
import labelUtils from '@admin/mixins/labelGenerator';
import mixDateFormatterToDisplay from '@admin/mixins/formatter';
import TrackerApi from '@admin_endpoints/tenant-tracker';
import TopActionBar from './components/TopActionBar';
import CreateNoteModal from './components/CreateNoteModal';
import CheckInCheckOutModal from './components/CheckInCheckOutModal';
import TableProperty from './mixins/tenantTracker';
import WhatsappTrackModal from './components/WhatsappTrackModal/WhatsappTrackModal.vue';

export default {
  name: 'TenantTracker',

  data() {
    return {
      isShowModalNote: false,
      isEmptyResult: false,
      isLoadContent: false,
      isLoadUpdate: false,
      isShowCardAction: {
        active: false,
        actionId: 0,
      },
      actionMarkTitle: 'Tandai sudah follow-up',
      communicationList: [],
      currentPage: 1,
      limitPage: 10,
      pageInfo: {},
      sortType: 'desc',
      storageParams: {},
      filterParams: {},
      noteValue: '',
      noteId: '',
      trackerId: '',
      bookingId: '',
      trackerData: {},
      trackerStatus: '',
      checkinCheckoutStatus: '',
      totalItem: 0,
      isShowWhatsappTrackModal: false,
      isNewWhatsappTrackData: false,
      isShowCheckInCheckOutModal: false,
    };
  },
  mixins: [TableProperty, labelUtils, mixDateFormatterToDisplay],
  components: {
    BgButtonIcon,
    BgText,
    BgIcon,
    BgCard,
    BgLink,
    BgListItem,
    BgSkeleton,
    TopActionBar,
    BgPagination,
    BgEmptyState,
    BgLabelRainbow,
    CreateNoteModal,
    WhatsappTrackModal,
    CheckInCheckOutModal,
  },

  created() {
    const paramsStorage = sessionStorage.getItem('searchParams');
    this.storageParams = paramsStorage ? JSON.parse(paramsStorage) : {};
    this.filterParams = paramsStorage ? JSON.parse(paramsStorage) : {};
    this.getTrackerList({ page: this.currentPage });
  },

  computed: {
    ...mapGetters(['xCheckUserPermission']),
    hasAccessCheckin() {
      return this.xCheckUserPermission('tenant-tracker-checkin');
    },

    totalPage() {
      return Math.ceil(this.totalItem / this.limitPage);
    },

    isPageReadyToDisplay() {
      return !this.isLoadContent && !this.isEmptyResult;
    },

    totalResultToDisplay() {
      const resultAccumulation = this.currentPage * this.limitPage;
      const totalResult =
        resultAccumulation < this.totalItem
          ? resultAccumulation
          : this.totalItem;
      return this.totalItem < this.limitPage ? this.totalItem : totalResult;
    },

    iconSortType() {
      return this.sortType === 'desc' ? 'down' : 'up';
    },

    confirmationType() {
      const checkinStatus = ['hari h check-in', 'belum check-in'];
      return checkinStatus.indexOf(this.checkinCheckoutStatus?.toLowerCase()) >=
        0
        ? 'check-in'
        : `check-out`;
    },
  },

  methods: {
    handleNoteValue(data) {
      return data.note?.message || '+ Tambah Catatan';
    },

    handleWhatsappTrackModal(isNewData) {
      this.isShowWhatsappTrackModal = !this.isShowWhatsappTrackModal;
      this.isNewWhatsappTrackData = isNewData;
    },

    isHeaderForDate(headerContent) {
      return headerContent === 'Tanggal Status';
    },

    isEnableCheckInCheckout(status, actionItem) {
      const checkinActionList = 'check-round';
      const enableStatus = ['hari h check-in', 'belum check-in'];

      const enableCheckin =
        this.hasAccessCheckin &&
        enableStatus.indexOf(status.toLowerCase()) >= 0;

      if (actionItem === checkinActionList && !enableCheckin) {
        return false;
      }

      return true;
    },

    handleShowNoteModal(data) {
      this.isShowModalNote = !this.isShowModalNote;
      if (data) {
        const { status: noteStatus, id, note } = data;
        this.noteValue = note?.message;
        this.noteId = note?.id;
        this.trackerId = id;
        this.trackerStatus = noteStatus;
      }
    },

    handleShowCheckInCheckOutModal(trackerId, bookingId, status) {
      this.trackerId = trackerId;
      this.bookingId = bookingId;
      this.checkinCheckoutStatus = status;
      this.isShowCheckInCheckOutModal = !this.isShowCheckInCheckOutModal;
    },

    showInvalidIdAlert(id, message) {
      if (!id) {
        alert(message);
      }
    },

    getProfilePageLinkAttribute(profileId, rowId) {
      if (profileId) {
        return {
          tag: 'RouterLink',
          to: `/tenant-tracker/profile/${profileId}/${rowId}`,
        };
      }

      return {};
    },

    getPropertyDetailOverviewPath(propertyId) {
      if (propertyId) {
        return `/property-detail/${propertyId}/overview`;
      }

      return null;
    },

    handleToggleAction(actionId, data, status) {
      if (this.isShowCheckInCheckOutModal) return;
      this.checkinCheckoutStatus = status?.toLowerCase();
      let isCardActive = this.isShowCardAction.active;

      if (typeof actionId === 'boolean') {
        isCardActive = false;
      } else {
        isCardActive = !isCardActive;
      }

      if (data) {
        this.trackerData = data;
      }

      this.isShowCardAction = {
        active: isCardActive,
        actionId,
      };
    },

    handleGetData(data) {
      this.currentPage = 1;
      this.filterParams = data;
      this.getTrackerList(data);
    },

    async getTrackerList(listParams) {
      const searchParams = {
        ...this.storageParams,
        ...listParams,
        limit: this.limitPage,
        sort_by: 'status_date',
        sort_order: this.sortType,
        page: this.currentPage,
      };
      sessionStorage.setItem('searchParams', JSON.stringify(searchParams));
      this.storageParams = {};
      this.isLoadContent = true;
      const response = await TrackerApi.getTrackerList(searchParams);

      if (response.data.status) {
        this.pageInfo = response.data.pagination;
        if (response.data.trackers.length > 0) {
          this.isEmptyResult = false;
          this.communicationList = response.data.trackers;
          this.totalItem = this.pageInfo?.total_items;
        } else {
          this.isEmptyResult = true;
        }
      } else {
        alert('Terjadi Galat, Silahkan coba lagi.');
        console.error(response.data);
      }

      this.isLoadContent = false;
    },

    async updateHandlingStatus(isHandled, trackerId) {
      const action = isHandled ? 'unhandled' : 'handled';

      try {
        this.isLoadUpdate = true;
        const params = { trackerId, action };
        await TrackerApi.updateHandlingTracker(params).then(response => {
          if (response.data?.status) {
            this.refreshTrackerList();
          } else {
            alert('Terjadi Galat, silahkan coba lagi');
          }
        });
      } catch (error) {
        alert('Terjadi Galat, silahkan coba lagi');
        console.error(error);
      } finally {
        this.handleToggleAction(false);
        this.isLoadUpdate = false;
      }
    },

    refreshTrackerList() {
      this.isShowModalNote = false;
      this.getTrackerList({
        page: this.currentPage,
        ...this.filterParams,
      });
    },

    updateSortingList(isUpdateSortType) {
      /* istanbul ignore else */
      if (isUpdateSortType) {
        const newSortType = this.sortType === 'desc' ? 'asc' : 'desc';
        this.sortType = newSortType;
        this.getTrackerList({
          page: this.currentPage,
          ...this.filterParams,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./TenantTracker.scss"></style>
