<template>
  <bg-modal
    v-model="isShowModal"
    :closeOnClickBackdrop="false"
    desktop-size="lg"
    @close="handleCloseModal"
  >
    <bg-text size="heading-4">Track Status Chat WhatsApp</bg-text>
    <bg-text class="whatsapp-track__subtitle" size="input-lg">
      Tambahkan informasi dan status dari chat WhatsApp penyewa
    </bg-text>
    <div class="whatsapp-track__wrapper">
      <!-- Phone Input -->
      <div class="whatsapp-track__col">
        <bg-text size="title-3">No. HP Penyewa </bg-text>
        <validation-observer ref="phoneNumber">
          <validation-provider
            name="Nomor Handphone"
            :rules="isRulesPhoneNumber"
            v-slot="{ errors }"
          >
            <bg-field
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-input
                v-model="trackParams.phone_number"
                ref="inputPhoneNumber"
                placeholder="No. HP Penyewa"
                :disabled="!isNewData"
                @focus="watchPhoneChanges"
              />
            </bg-field>

            <template v-if="phoneNumberLoading.isLoading && !errors[0]">
              <bg-text size="body-4" class="mt-8">
                Mohon menunggu, sedang memuat data.
              </bg-text>
            </template>

            <template v-else-if="phoneNumberLoading.isLoaded && !errors[0]">
              <bg-text
                size="body-4"
                class="whatsapp-track__message"
                :class="validationClass"
              >
                {{ validationMessage }}
                <span v-if="dataValid">
                  -
                  <bg-link
                    class="whatsapp-track__message-valid"
                    @click="handleApplyNumber"
                  >
                    Gunakan nomor ini
                  </bg-link>
                </span>
              </bg-text>
            </template>
          </validation-provider>
        </validation-observer>
      </div>
      <!-- Tenant Name -->
      <div class="whatsapp-track__col">
        <bg-text size="title-3">Nama Penyewa</bg-text>
        <validation-observer ref="tenantName">
          <validation-provider
            name="Nama Penyewa"
            :rules="isRulesTenantName"
            v-slot="{ errors }"
          >
            <bg-field
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-input
                placeholder="Nama Penyewa"
                v-model="trackParams.user_name"
                :disabled="dataValid"
                @focus="watchTenantNameChanges"
              />
            </bg-field>
          </validation-provider>
        </validation-observer>
      </div>
    </div>

    <!-- Property Name -->
    <bg-text size="title-3" class="whatsapp-track__subtitle-space-top">
      Nama Properti
    </bg-text>

    <bg-search-bar
      v-model="propertyName"
      placeholder="Nama Properti"
      :suggestions="propertySuggestions"
      :show-search-icon="false"
      :loading="propertyLoading.isLoading"
      :disabled="!isNewData"
      :show-clear-text-icon="isNewData"
      @focus="watchPropertyChanges"
      @click-suggestion="handleClickSuggestion"
    />

    <bg-text
      v-if="isEmptyProperty"
      size="body-4"
      class="mt-8 whatsapp-track__message-invalid"
    >
      Data wajib diisi.
    </bg-text>

    <bg-text
      v-else-if="isPropertyNotFound"
      size="body-4"
      class="mt-8 whatsapp-track__message-invalid"
    >
      Data tidak ditemukan.
    </bg-text>

    <!-- Note Message -->
    <bg-text size="title-3" class="whatsapp-track__subtitle-space-top">
      Catatan
    </bg-text>
    <div class="whatsapp-track__wrapper">
      <bg-textarea
        placeholder="Tulis catatan di sini"
        v-model="trackParams.message"
        :max-char="300"
        :max-length="300"
      />
      <bg-text size="label-2" class="whatsapp-track__maximum-text">
        Maksimal 300 karakter
      </bg-text>
    </div>

    <template #footer>
      <div class="flex justify-end">
        <bg-button class="mr-16" size="lg" @click="handleResetTrack()">
          Reset
        </bg-button>
        <bg-button
          variant="primary"
          size="lg"
          :disabled="isDisabledButton"
          @click="handleAddTrack"
        >
          Tambah
        </bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
import {
  BgModal,
  BgText,
  BgField,
  BgInput,
  BgTextarea,
  BgSearchBar,
  BgLink,
  BgButton,
} from 'bangul-vue';
import TenantTrackerApi from '@admin_endpoints/tenant-tracker';

import axios from 'axios';
import _debounce from 'lodash/debounce';

import '@admin/pages/TenantTracker/config/validationRules.js';
import { ValidationProvider } from 'vee-validate';

const FETCH_API_DELAY = 500;
const DEFAULT_ERROR_MESSAGE = 'Terjadi galat, silakan coba lagi.';

export default {
  name: 'WhatsappTrackModal',
  data() {
    return {
      trackParams: {
        user_name: '',
        phone_number: '',
        message: '',
        room_type_id: '',
        user_id: '',
      },
      propertyName: '',
      propertySuggestions: [],
      isShowModal: false,
      validationMessage: '',
      dataValid: true,
      applySuggestionData: {},
      searchCancelToken: null,
      phoneNumberLoading: {
        isLoading: false,
        isLoaded: false,
      },
      propertyLoading: {
        isLoading: false,
        isLoaded: false,
      },
      isValidPhoneNumber: false,
      isValidTenantName: false,
      phoneChangesUnwatchFn: null,
    };
  },

  components: {
    BgText,
    BgField,
    BgModal,
    BgInput,
    BgTextarea,
    BgSearchBar,
    BgLink,
    BgButton,
    ValidationProvider,
  },

  watch: {
    showModal(isOpen) {
      this.isShowModal = isOpen;
      if (this.trackData && !this.isNewData) {
        this.propertyName = this.trackData.room_type_name;
        this.trackParams = {
          user_name: this.trackData.user_name,
          user_id: this.trackData.user_id,
          phone_number: this.trackData.user_phone_number,
          room_type_id: this.trackData.room_type_id,
        };
      } else {
        this.handleResetTrack();
      }

      this.unwatchPhoneChanges();
    },
    'trackParams.phone_number': {
      immediate: true,
      handler(phoneNumber) {
        let validPhoneNumber = phoneNumber.replace(/\D+/g, '');

        if (validPhoneNumber.startsWith('628')) {
          validPhoneNumber = validPhoneNumber.replace('628', '08');
        }

        this.trackParams.phone_number = validPhoneNumber;
      },
    },
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    trackData: {
      type: Object,
      default: () => {},
    },
    isNewData: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    handleCloseModal() {
      this.isShowModal = false;
      this.handleResetTrack();
      this.$emit('close-modal');
    },

    async handleResetTrack() {
      if (this.isNewData) {
        this.trackParams = {
          user_name: '',
          phone_number: '',
          message: '',
          room_type_id: '',
          user_id: '',
        };
        this.propertyName = '';
        this.validationMessage = '';
        this.dataValid = true;
        this.phoneNumberLoading = {
          isLoading: false,
          isLoaded: false,
        };
        this.propertyLoading = {
          isLoading: false,
          isLoaded: false,
        };

        setTimeout(() => {
          this.$refs?.phoneNumber?.reset();
          this.$refs?.tenantName?.reset();
        }, 0);
      } else {
        this.trackParams.message = '';
      }
    },

    handleClickSuggestion(selected) {
      if (!this.isNewData) return;

      const { label, id } = selected;

      if (id) {
        this.propertyName = label;
        this.trackParams.room_type_id = id;
        this.propertySuggestions = [];
      }
    },

    async handleAddTrack() {
      try {
        let response;
        if (this.isWhatsappStatus) {
          response = await TenantTrackerApi.updateWhatsappTracker(
            this.trackParams,
            this.trackData.id
          );
        } else {
          response = await TenantTrackerApi.postWhatsappTracker(
            this.trackParams
          );
        }

        if (response.data.status) {
          window.location.reload();
          this.$toast.show('Status baru berhasil ditambahkan');
        } else if (response.data.error_code === 422) {
          alert('Semua field harus diisi');
          console.error(response.data);
        } else {
          this.handleApiRequestError({ response });
        }
      } catch (error) {
        this.handleApiRequestError(error);
      }
    },

    async handleApplyNumber() {
      this.trackParams.user_name = this.applySuggestionData?.value || '';
      this.trackParams.user_id = this.applySuggestionData?.id || '';

      await this.$nextTick();
      await this.getIsValidObserverBy('tenantName');
    },

    cancelFetchApi() {
      if (this.searchCancelToken) {
        this.searchCancelToken.cancel();
      }

      const { CancelToken } = axios;
      this.searchCancelToken = CancelToken.source();
    },

    handleApiRequestError(error) {
      const errorMessage =
        error?.response?.data?.error || DEFAULT_ERROR_MESSAGE;

      this.$toast.show(errorMessage);
      this.$error.report(error);
    },

    async getIsValidObserverBy(ref) {
      const isValid = await this.$refs?.[ref]?.validate();
      const state =
        ref === 'phoneNumber' ? 'isValidPhoneNumber' : 'isValidTenantName';

      this[state] = isValid;

      return isValid;
    },

    watchPhoneChanges() {
      this.phoneChangesUnwatchFn = this.$watch(
        'trackParams.phone_number',
        async phoneNumber => {
          this.cancelFetchApi();
          // Reset tenant name form and errors
          this.trackParams.user_name = '';
          this.$refs?.tenantName?.reset();

          await this.$nextTick();
          const isValid = await this.getIsValidObserverBy('phoneNumber');

          if (isValid && phoneNumber) {
            this.phoneNumberLoading.isLoading = true;
            this.phoneNumberLoading.isLoaded = false;
            this.handleDataCompletionBy('phone_number', phoneNumber);
          }
        }
      );
    },

    watchTenantNameChanges() {
      this.$watch('trackParams.user_name', async _ => {
        await this.$nextTick();
        await this.getIsValidObserverBy('tenantName');
      });
    },

    watchPropertyChanges() {
      this.$watch('propertyName', async value => {
        this.cancelFetchApi();

        if (value) {
          this.propertyLoading.isLoading = true;
          this.propertyLoading.isLoaded = false;
          this.handleDataCompletionBy('room', value);
        } else {
          this.propertySuggestions = [];
        }
      });
    },

    unwatchPhoneChanges() {
      if (typeof this.phoneChangesUnwatchFn === 'function') {
        this.phoneChangesUnwatchFn();
      }
    },

    setLoadingBy(type, key, value) {
      const state =
        type === 'phone_number' ? 'phoneNumberLoading' : 'propertyLoading';

      this[state][key] = value;
    },

    handleDataCompletionBy: _debounce(async function (type, value) {
      if (!this.isNewData) return;

      try {
        const response = await TenantTrackerApi.getTrackerDataSuggestions(
          { type, value },
          this.searchCancelToken.token
        );

        if (response.data) {
          const suggestions = response.data.suggestions || [];
          if (type === 'phone_number') {
            if (suggestions.length > 0) {
              this.applySuggestionData = suggestions[0];
              this.validationMessage = 'Nomor Tenant terdaftar';
              this.dataValid = true;
            } else {
              this.trackParams.user_name = '';
              this.trackParams.user_id = '';
              this.validationMessage = 'Nomor Tenant tidak terdaftar';
              this.dataValid = false;
            }
          } else if (type === 'room') {
            this.propertySuggestions = suggestions.map(suggest => ({
              label: suggest.value,
              id: suggest.id,
            }));
          }

          this.setLoadingBy(type, 'isLoaded', true);
          this.setLoadingBy(type, 'isLoading', false);
        } else {
          this.handleApiRequestError({ response });
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          this.setLoadingBy(type, 'isLoaded', false);
        } else {
          this.handleApiRequestError(error);
          this.setLoadingBy(type, 'isLoaded', true);
          this.setLoadingBy(type, 'isLoading', false);
        }
      }
    }, FETCH_API_DELAY),
  },

  computed: {
    isWhatsappStatus() {
      return this.trackData.status === 'whatsapp';
    },
    validationClass() {
      return this.dataValid
        ? 'whatsapp-track__message-valid'
        : 'whatsapp-track__message-invalid';
    },
    isEmptyProperty() {
      return !!(this.propertyLoading.isLoaded && !this.propertyName);
    },
    isPropertyNotFound() {
      return (
        !!this.propertyName &&
        !this.propertySuggestions.length &&
        this.propertyLoading.isLoaded
      );
    },
    isRulesTenantName() {
      return this.isNewData ? 'required' : '';
    },
    isRulesPhoneNumber() {
      return this.isNewData ? 'required|phone_format|numeric|min:8|max:14' : '';
    },
    isDisabledButton() {
      if (!this.isNewData) return false;

      return (
        !this.trackParams.phone_number ||
        !this.trackParams.user_name ||
        !this.trackParams.room_type_id ||
        !this.isValidPhoneNumber ||
        !this.isValidTenantName ||
        this.isEmptyProperty
      );
    },
  },
};
</script>
<style lang="scss" scoped src="./WhatsappTrackModal.scss"></style>
