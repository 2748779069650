/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import {
  required,
  max,
  numeric,
  min,
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Data wajib diisi',
});

extend('phone_format', {
  validate(value = '') {
    return /^08.*/g.test(value);
  },
  message: 'Nomor handphone harus diawali dengan 08',
});

extend('min', {
  ...min,
  params: ['length'],
  message: 'Minimal {length} karakter',
});

extend('max', {
  ...max,
  params: ['length'],
  message: 'Maksimal {length} karakter',
});

extend('numeric', {
  ...numeric,
  message: 'Hanya dapat diisi dengan angka',
});
